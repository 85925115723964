<template>
  <div class="topbar d-flex align-items-center">
    <p class="h4 mb-0 mr-3">
      <transition name="expand">
        <b-icon
          v-if="showHamburger"
          class="hamburger-toggle"
          icon="list"
          @click="onMenuToggle"
        />
      </transition>
    </p>
    <span @click="$router.push('/')" class="cursor-pointer">
      <b-icon
        icon="house-fill"
        class="mr-2"
        :style="{ fontSize: '1.4rem' }"
      ></b-icon>
      <strong>WHAPPY</strong> Enterprise Gamification Platform
    </span>
    <div v-if="loadingDiskDataInfo" class="loading-spazio-su-disco ml-auto">
      Calcolo spazio utilizzato...
    </div>
    <div v-else class="disk-info ml-auto">
      <div
        class="disk-progress d-flex justify-content-around align-items-center"
      >
        <span>Quota disco</span>
        <b-progress
          :value="diskDataInfo.value"
          :max="diskDataInfo.max"
          show-progress
          class="flex-grow-1 ml-2"
        >
          <b-progress-bar :value="diskDataInfo.value">
            <span>{{ diskUsagePercentage }}%</span>
          </b-progress-bar>
        </b-progress>
      </div>
      <div class="disk-details d-flex justify-content-end">
        <span>{{ diskDataInfo.value.toFixed(2) }} MB</span>
        <span>/ {{ (diskDataInfo.max / 1024).toFixed(0) }} GB</span>
      </div>
    </div>

    <div class="user-info ml-auto">
      <div class="d-flex justify-content-end align-items-end mr-4">
        <b-skeleton class="w-100" v-if="loadingCompanyList"></b-skeleton>
        <b-form-group
          v-else-if="companyList && companyList.length > 0"
          class="m-0"
        >
          <b-form-select
            v-model="selectedCompany"
            :options="companyList"
            @change="companyChange"
          ></b-form-select>
        </b-form-group>
      </div>
      <span class="mr-2"
        >Hi, <strong>{{ userName }}</strong></span
      >
      <b-dropdown right size="sm" variant="light" class="mr-2">
        <template #button-content>
          <b-avatar :src="loggedUser.avatar_url"></b-avatar>
        </template>
        <b-dropdown-item @click="gotoProfile">Profile</b-dropdown-item>
        <b-dropdown-item @click="logout">Logout</b-dropdown-item>
      </b-dropdown>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { AUTH_LOGOUT } from "../store/actions/auth";
import { SET_CURRENT_COMPANY, SET_COMPANY_LIST } from "../store/actions/config";
import AxiosService from "@/axiosServices/AxiosService";

export default {
  props: ["showHamburger"],
  data() {
    return {
      diskDataInfo: {
        value: 0,
        max: 0,
      },
      loadingDiskDataInfo: false,

      selectedCompany: null,
      companyList: [],
      loadingCompanyList: false,
    };
  },
  created() {
    this.getDiskDataInfo();
    this.getCompanyList();
  },
  computed: {
    ...mapGetters(["loggedUser"]),
    diskUsagePercentage() {
      return ((this.diskDataInfo.value / this.diskDataInfo.max) * 100).toFixed(
        2
      );
    },
    userName() {
      return Object.keys(this.loggedUser).length !== 0
        ? this.loggedUser.nome
        : "User";
    },
    userInitial() {
      return Object.keys(this.loggedUser).length !== 0
        ? this.loggedUser.nome.charAt(0)
        : "U";
    },
    currentCompanyFromStore() {
      return this.$store.getters.currentCompany;
    },
  },
  watch: {
    currentCompanyFromStore() {
      this.selectedCompany = this.currentCompanyFromStore;
    },
  },
  methods: {
    companyChange() {
      this.$store.dispatch(SET_CURRENT_COMPANY, this.selectedCompany);
    },
    setGlobalCompany(id_company) {
      this.$store.dispatch(SET_CURRENT_COMPANY, id_company);
    },
    setCompanyList(companyList) {
      this.$store.dispatch(SET_COMPANY_LIST, companyList);
    },
    async getCompanyList() {
      this.loadingCompanyList = true;
      this.companyList.splice(0);
      const service = new AxiosService("Company");
      try {
        let res = await service.getOptions("Company");
        this.companyList = res;
        this.selectedCompany = this.companyList[0].value;
        this.setGlobalCompany(this.selectedCompany);
        this.setCompanyList(this.companyList);
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingCompanyList = false;
      }
    },
    getDiskDataInfo() {
      this.loadingDiskDataInfo = true;
      const service = new AxiosService(`System/DiskDataInfo`);
      service
        .read()
        .then((res) => {
          this.diskDataInfo.max = res.mbDisponibili;
          this.diskDataInfo.value = res.mbUtilizzati;
        })
        .catch((err) => {
          console.error("Errore nel calcolo dello spazio su disco:", err);
          this.$bvToast.toast("Errore nel calcolo dello spazio su disco", {
            title: "Errore",
            variant: "danger",
            solid: true,
          });
        })
        .finally(() => {
          this.loadingDiskDataInfo = false;
        });
    },
    onMenuToggle(event) {
      this.$emit("menu-toggle", event);
    },
    logout() {
      this.$store.dispatch(AUTH_LOGOUT).then(() => {
        this.$router.push("/login");
      });
    },
    gotoProfile() {
      this.$router.push("/profile");
    },
  },
};
</script>

<style scoped>
.topbar {
  padding: 10px;
  background-color: #f8f9fa;
}

.disk-info {
  width: 250px;
}

.disk-progress {
  font-size: 12px;
}

.disk-details {
  font-size: 10px;
}

.user-info {
  display: flex;
  align-items: center;
}
</style>
