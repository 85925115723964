<template>
  <div class="sidebar no-scrollbar d-flex flex-column">
    <div
      class="sidebar-header d-flex justify-content-between align-items-center"
    >
      <div
        class="logo-image-rounded"
        :style="{
          backgroundImage: 'url(' + company.logo + ')',
        }"
      ></div>
      <i class="double-arrow left" @click="onMenuToggle"></i>
    </div>

    <template v-for="(group, i) of model">
      <div :key="i">
        <div class="sidebar-group-lable">{{ group.group }}</div>
        <AppSubmenu
          :items="group.items"
          class="layout-menu"
          :root="true"
          @menuitem-click="onMenuItemClick"
        />
      </div>
    </template>

    <div class="mt-auto pt-5 pb-2 d-flex px-3 align-items-center">
      <div class="text-white mr-2" style="font-size: 14px">Powered by</div>
      <img
        src="@/assets/images/logo-whappy-white.png"
        style="max-width: 90px"
      />
    </div>
  </div>
</template>

<script>
import AppSubmenu from "./AppSubmenu";
import AxiosService from "../axiosServices/AxiosService";
import { mapGetters } from "vuex";
export default {
  props: {
    // model: Array,
  },
  service: null,
  created() {
    this.service = new AxiosService("System/GetUserBMenu");
    this.service.read().then((res) => {
      // console.log(res.menu);
      this.model = res.menu;
    });
  },
  data() {
    return {
      model: [],
    };
  },
  methods: {
    onMenuItemClick() {
      this.$emit("menuitem-click");
    },
    onMenuToggle(event) {
      this.$emit("menu-toggle", event);
    },
  },
  components: {
    AppSubmenu: AppSubmenu,
  },
  computed: mapGetters(["company"]),
};
</script>
