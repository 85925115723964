<template>
  <div class="tags-input">
    <div class="selected-tags-wrapper">
      <b-form-tag
        v-for="tag in selected"
        :key="tag"
        @remove="removeTag(tag)"
        variant="primary"
        :disabled="disabled"
      >
        {{ getText(tag) }}
      </b-form-tag>
    </div>
    <div class="tag-list">
      <b-badge
        v-for="tag in tagsListOptions"
        :key="tag[TagValueField]"
        variant="secondary"
        class="mr-2 pointer"
        @click="addTag(tag)"
        >{{ tag[TagTextField] }}</b-badge
      >
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: ["value", "tagsList", "text-field", "value-field", "disabled"],
  data() {
    return {
      selected: this.value,
      TagTextField: this.textField || "text",
      TagValueField: this.valueField || "value",
    };
  },
  methods: {
    addTag(tag) {
      this.selected.push(tag[this.TagValueField]);
      this.updateValue();
    },
    removeTag(tag) {
      this.selected = this.selected.filter((x) => x != tag);
      this.updateValue();
    },
    getText(value) {
      const tag = this.tagsList.find((x) => x[this.TagValueField] === value);
      return tag ? tag[this.TagTextField] : "";
    },
    updateValue() {
      this.$emit("input", this.selected);
    },
  },
  computed: {
    tagsListOptions() {
      let list = [];
      this.tagsList.forEach((element) => {
        if (!this.selected.includes(element[this.TagValueField])) {
          list.push(element);
        }
      });
      return list;
    },
  },
  watch: {
    value: function(newVal) {
      this.selected = newVal;
    },
  },
};
</script>
