import Vue from "vue";
import Vuex from "vuex";
import config from "./modules/config";
import auth from "./modules/auth";
import user from "./modules/user";
import menu from "./modules/menu";
import pusher from "./modules/pusher";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    config,
    auth,
    user,
    menu,
    pusher,
  },
});
