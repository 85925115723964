<template>
  <div class="px-4 py-2">
    <b-button
      @click="chatVisible = !chatVisible"
      class="enterprise-button"
      :disabled="loading"
    >
      <b-icon icon="chat"></b-icon>
    </b-button>

    <div
      v-show="chatVisible"
      ref="chatWindowContainer"
      class="chat-window"
      style="height: 600px;"
    ></div>
  </div>
</template>

<script>
import { AxertaChat } from "@/utils/axerta";

export default {
  name: "TALKJS-BUTTON",

  data() {
    return {
      loading: true,
      chatVisible: false,
      session: null,
      me: null,
    };
  },

  mounted() {
    setTimeout(async () => {
      await this.$nextTick();

      await this.handleCreationTalkjsSession();

      const inbox = await this.session.createInbox();

      // inbox.select("1ac8c0752657d818080a");

      inbox.mount(this.$refs.chatWindowContainer);

      this.loading = false;
    }, 1200);
  },

  methods: {
    async handleCreationTalkjsSession() {
      const loggedBo = this.$store.getters.loggedUser;
      const company = this.$store.getters.company;
      try {
        const boUser = {
          id: `${company.nome}_BO_${loggedBo.id}`,
          fullname: `${loggedBo.nome} ${loggedBo.cognome}`,
          email: loggedBo.email,
          avatar_url:
            loggedBo.avatar_url ||
            `https://avatar.iran.liara.run/public/${loggedBo.id}`,
          welcomeMessage: `Conversazione con ${loggedBo.nome} ${loggedBo.cognome}.`,
          role: "BO",
        };

        const session = await AxertaChat.createSession("tjBGvoRX", boUser);

        this.session = session;
      } catch (error) {
        console.error("Errore nella creazione della sessione TalkJS:", error);
      }
    },
  },
};
</script>

<style scoped>
.enterprise-button {
  width: 60px;
  height: 60px;
  background-color: var(--whappy-primary);
  color: var(--whappy-secondary);
  border: 2px solid var(--whappy-secondary);
  border-radius: 50%;
  padding: 5px;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.enterprise-button:disabled {
  background-color: #ccc;
  color: #666;
  border: 2px solid #999;
  cursor: not-allowed;
  box-shadow: none;
}

.enterprise-button:hover:not(:disabled) {
  background-color: var(--whappy-secondary);
  color: var(--whappy-primary);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.chat-window {
  position: fixed;
  bottom: 80px;
  right: 0;
  z-index: 1000;
  width: 750px;
}
</style>
