<template>
  <div class="content-wrapper dashboard-page">
    <h1>Dashboard</h1>
    <!-- <b-overlay :show="showOverlay"> -->
    <b-overlay :show="showOverlay">
      <b-card>
        <!-- FILTRO -->
        <b-form-group>
          <b-row>
            <b-col>
              <b-form-select
                @change="handleCampaignSelected"
                v-model="campaignSelected"
                :options="campaignFilteredByCompany"
              ></b-form-select>
            </b-col>
            <b-col>
              <b-form-select
                v-model="roleSelected"
                :options="roleFilteredByCompany"
              ></b-form-select>
            </b-col>
            <b-col>
              <div class="d-flex flex-column align-items-start">
                <b-form-datepicker
                  id="datepicker"
                  v-model="datepickerValue"
                  class="mb-2"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"
                  dropup
                ></b-form-datepicker>
                <label
                  for="datepicker"
                  class="ml-2 text-left"
                  style="min-width: 3rem"
                  >From</label
                >
              </div>
            </b-col>
            <b-col>
              <div class="d-flex flex-column align-items-start">
                <b-form-datepicker
                  id="datepicker2"
                  v-model="datepickerValue2"
                  class="mb-2"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"
                  dropup
                ></b-form-datepicker
                ><label
                  for="datepicker2"
                  class="ml-2 text-left"
                  style="min-width: 3rem"
                >
                  To</label
                >
              </div>
            </b-col>
          </b-row>
          <b-row align-h="end">
            <b-col cols="auto">
              <b-button @click="caricaDati">Carica dati</b-button>
            </b-col>
          </b-row>
        </b-form-group>

        <!-- DASHBOARD -->
        <b-form-group class="m-0">
          <b-tabs fill ref="dash-table">
            <b-tab
              @click="caricaDati"
              lazy
              title="Global Data"
              no-body
              title-link-class="tab-link-style"
            >
              <global-data
                :showGraph="!showOverlay"
                :dataUsersPie="globalDataUsersPie"
                :dataUsersBars="globalDataUsersBars"
                :loadingUsersPie="loading.global.usersPie"
                :loadingUsersBars="loading.global.usersBars"
                :dataSessionsPie="globalDataSessionsPie"
                :dataSessionsBars="globalDataSessionsBars"
                :loadingSessios="loading.global.sessions"
              ></global-data>
            </b-tab>
            <b-tab
              @click="caricaDati"
              lazy
              title="Social Share"
              no-body
              title-link-class="tab-link-style"
            >
              <social-share
                :showGraph="!showOverlay"
                :dataSocialShare="SocialShareStatic"
              ></social-share>
            </b-tab>
            <b-tab
              @click="caricaDati"
              lazy
              title="Learning"
              no-body
              title-link-class="tab-link-style"
            >
              <learning
                :loadingLearning="loading.learning.pie"
                :dataLearning="LearningStatic"
              ></learning>
            </b-tab>
            <b-tab
              @click="caricaDati"
              lazy
              title="Kpis"
              no-body
              title-link-class="tab-link-style"
            >
              <kpis :dataKpi="kpiData" :loadingKpi="loading.kpi.pie"></kpis>
            </b-tab>
            <b-tab
              @click="caricaDati"
              disabled
              lazy
              title="Tasks"
              no-body
              title-link-class="tab-link-style"
            >
              <tasks></tasks>
            </b-tab>
            <b-tab
              @click="caricaDati"
              lazy
              title="Badges and Awards"
              no-body
              title-link-class="tab-link-style"
            >
              <badge-and-awards
                :showGraph="!showOverlay"
                :badgesData="badgesData"
                :awardsData="awardsData"
                :loadingBadges="loading.badge.pie"
                :loadingAwards="loading.awards.pie"
              ></badge-and-awards>
            </b-tab>
          </b-tabs>
        </b-form-group>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import AxiosService from "./../axiosServices/AxiosService";

import GlobalData from "../components/DashBoard/GlobalData.vue";
import SocialShare from "../components/DashBoard/SocialShare.vue";
import BadgeAndAwards from "../components/DashBoard/BadgeAndAwards.vue";
import Kpis from "../components/DashBoard/Kpis.vue";
import Learning from "../components/DashBoard/Learning.vue";
// import Tasks from "../components/DashBoard/Tasks.vue";
import { SET_CURRENT_COMPANY } from "../store/actions/config";
export default {
  components: {
    GlobalData,
    SocialShare,
    BadgeAndAwards,
    Kpis,
    Learning,
    // Tasks,
  },
  data() {
    return {
      showOverlay: false,
      companySelected: 0,
      companyOptions: [{ text: "All Companies", value: 0 }],
      campaignSelected: 0,
      campaignOptions: ["All campaigns"],
      roleSelected: 0,
      roleOptions: ["All roles"],
      datepickerValue: "",
      datepickerValue2: "",

      loading: {
        global: {
          usersPie: false,
          usersBars: false,
          sessions: false,
        },
        kpi: {
          pie: false,
        },
        badge: {
          pie: false,
        },
        awards: {
          pie: false,
        },
        learning: {
          pie: false,
        },
      },

      globalDataUsersPie: null,
      globalDataUsersBars: {
        labels: [],
        values: [],
      },
      globalDataSessionsPie: [],
      globalDataSessionsBars: {
        etichette_G1: [],
        valori_G1: [],
        etichette_G2: [],
        valori_G2: [],
      },

      SocialShareStatic: null,
      LearningStatic: {
        data: null,
        objectToPost: null,
      },
      kpiData: null,
      badgesData: null,
      awardsData: null,
    };
  },
  serviceCompany: null,
  serviceCampaigns: null,
  serviceRole: null,
  generalService: null,
  created() {
    this.serviceCompany = new AxiosService("UserBO");
    this.serviceCampaigns = new AxiosService("Campaign");
    this.serviceRole = new AxiosService("Role");
  },
  async mounted() {
    let today = new Date();
    this.datepickerValue = new Date(today.getFullYear(), today.getMonth(), 1);
    this.datepickerValue2 = new Date(
      today.getFullYear(),
      today.getMonth() + 1,
      0
    );

    //get companyOptions
    await this.serviceCompany.getOptions("Company").then((data) => {
      data.forEach((element) => {
        this.companyOptions.push(element);
      });
    });

    this.companySelected = this.currentCompanyFromStore;
    //get campaignOptions
    this.serviceCampaigns.read().then((data) => {
      data.forEach((element) => {
        this.campaignOptions.push(element);
      });
    });
    //get roleOptions
    this.getRoles();
  },
  methods: {
    caricaDati() {
      let currentTab = this.$refs["dash-table"].currentTab;
      let endPoint;

      let objectToPost = {
        idCompany: this.companySelected,
        idCampaign: this.campaignSelected,
        idRole: this.roleSelected,
        dataInizio: this.datepickerValue,
        dataFine: this.datepickerValue2,
      };
      switch (currentTab) {
        // Global data
        case 0:
          this.loading.global.pie = true;
          this.loading.global.bars = true;
          this.loading.global.sessios = true;
          this.loading.kpi.pie = true;
          this.loading.badge.pie = true;
          this.loading.awards.pie = true;
          this.loading.learning.pie = true;

          endPoint = "Dashboard/GetUserInfo";
          this.generalService = new AxiosService(endPoint);
          this.showOverlay = true;
          this.generalService
            .create(objectToPost)
            .then((res) => {
              this.globalDataUsersPie = res;
            })
            .finally(() => {
              this.showOverlay = false;
              this.loading.global.pie = false;
            });

          let serviceUsersBars = new AxiosService("Dashboard/GetUserInfoDaily");

          serviceUsersBars
            .create(objectToPost)
            .then((res) => {
              this.globalDataUsersBars.labels = res.etichette;
              this.globalDataUsersBars.values = res.valori;
            })
            .finally(() => {
              this.loading.global.bars = false;
            });

          let serviceSessions = new AxiosService("Dashboard/GetUserSession");
          serviceSessions
            .create(objectToPost)
            .then((res) => {
              this.globalDataSessionsPie = [res.utenti, res.sessioni];
              this.globalDataSessionsBars.etichette_G1 = res.etichette_G1;
              this.globalDataSessionsBars.etichette_G2 = res.etichette_G2;
              this.globalDataSessionsBars.valori_G1 = res.valori_G1;
              this.globalDataSessionsBars.valori_G2 = res.valori_G2;
            })
            .finally(() => {
              this.loading.global.sessions = false;
            });
          break;

        //Social Share
        case 1:
          // like, comment, share

          endPoint = "Dashboard/GetSocialShareData";
          this.generalService = new AxiosService(endPoint);
          this.showOverlay = true;
          this.generalService.create(this.globalData).then((res) => {
            console.log(res);
            this.SocialShareStatic = res;
            this.showOverlay = false;
          });
          break;

        //Learning
        case 2:
          endPoint = "Dashboard/GetLearningSessionNew";
          this.generalService = new AxiosService(endPoint);
          this.showOverlay = true;
          this.generalService
            .create(objectToPost)
            .then((res) => {
              this.LearningStatic.data = res;
              this.LearningStatic.objectToPost = objectToPost;
            })
            .finally(() => {
              this.loading.learning.pie = false;
              this.showOverlay = false;
            });
          break;

        //Kpi
        case 3:
          endPoint = "Dashboard/GetKpiDataNew";
          this.generalService = new AxiosService(endPoint);
          this.showOverlay = true;
          this.generalService
            .create(objectToPost)
            .then((res) => {
              this.kpiData = res;
            })
            .finally(() => {
              this.showOverlay = false;
              this.loading.kpi = false;
            });
          break;

        //Tasks
        case 4:
          break;

        //Badges and Awards
        case 5:
          console.log("badges and awards ");
          let endPointBadges = "Dashboard/GetBadgeDataNew";
          this.serviceBadges = new AxiosService(endPointBadges);
          this.showOverlay = true;
          this.serviceBadges
            .create(objectToPost)
            .then((res) => {
              this.badgesData = res;
            })
            .finally(() => {
              this.showOverlay = false;
              this.loading.badge.pie = false;
            });

          let endPointAwards = "Dashboard/GetAwardsDataNew";
          this.serviceAwards = new AxiosService(endPointAwards);
          this.showOverlay = true;
          this.serviceAwards
            .create(objectToPost)
            .then((res) => {
              this.awardsData = res;
            })
            .finally(() => {
              this.showOverlay = false;
              this.loading.awards.pie = false;
            });
          break;

        default:
      }

      // this.getDashboardData();
    },
    handleCampaignSelected() {
      this.getRoles();
      const selectedCampaign = this.campaignOptions.find(
        (element) => element.id == this.campaignSelected
      );

      if (selectedCampaign) {
        this.datepickerValue = new Date(selectedCampaign.data_inizio);
        this.datepickerValue2 = new Date(selectedCampaign.data_fine);
      }
    },
    getRoles() {
      this.roleOptions.length = 0;
      if (this.campaignSelected == 0) {
        this.serviceRole.read().then((data) => {
          data.forEach((element) => {
            this.roleOptions.push(element);
          });
        });
      } else {
        this.serviceRole
          .readCustomEndpoint(
            "CampaignManager/GetRolesByCampaign/",
            this.campaignSelected
          )
          .then((res) => {
            res.forEach((element) => {
              this.roleOptions.push(element);
            });
          });
      }
    },
  },
  computed: {
    currentCompanyFromStore() {
      return this.$store.getters.currentCompany;
    },
    globalData() {
      return {
        idCompany: this.companySelected,
        idCampaign: this.campaignSelected,
        idRole: this.roleSelected,
        dataInizio: this.datepickerValue,
        dataFine: this.datepickerValue2,
      };
    },
    campaignFilteredByCompany() {
      let array = [{ text: "All campaigns", value: 0 }];
      this.campaignOptions.forEach((element) => {
        if (element.id_company == this.companySelected) {
          element.text = element.title["it-IT"];
          element.value = element.id;
          array.push(element);
        }
      });
      return array;
    },
    roleFilteredByCompany() {
      let array = [{ text: "All roles", value: 0 }];

      if (this.roleOptions[0].id_company) {
        this.roleOptions.forEach((element) => {
          if (element.id_company == this.companySelected) {
            element.text = element.nome;
            element.value = element.id;
            array.push(element);
          }
        });
        return array;
      } else {
        this.roleOptions.forEach((element) => {
          array.push(element);
        });
        return array;
      }
    },
  },
  watch: {
    currentCompanyFromStore() {
      this.companySelected = this.currentCompanyFromStore;
    },
    companySelected() {
      this.$store.dispatch(SET_CURRENT_COMPANY, this.companySelected);
    },
  },
};
</script>
