<template>
  <div class="w-100 flex flex-wrap justify-content-end align-items-center">
    <span
      v-for="option in options"
      :key="option.value"
      :class="[
        'mr-1 mb-2 px-2 py-1 rounded cursor-pointer',
        {
          'bg-selected text-white text-20': option.value === selectedLang,
          'bg-white': option.value !== selectedLang,
        },
      ]"
      @click="selectedLang = option.value"
    >
      {{ getFlagEmoji(option.value) }}
    </span>
  </div>
</template>

<script>
export default {
  props: ["value", "options"],
  data() {
    return {
      selectedLang: this.value, // Store the selected language
    };
  },
  watch: {
    selectedLang(newVal) {
      this.$emit("input", newVal); // Emit the selected language back to the parent
    },
  },
  methods: {
    getFlagEmoji(locale) {
      // Extract the country code from the locale string
      const countryCode = locale.split("-")[1];

      // Check if the country code is present
      if (!countryCode) {
        return "";
      }

      // Convert the country code to uppercase
      const upperCountryCode = countryCode.toUpperCase();

      // Convert each letter to the corresponding regional indicator symbol
      const flag = String.fromCodePoint(
        ...[...upperCountryCode].map((char) => 0x1f1e6 - 65 + char.charCodeAt())
      );

      return flag;
    },
  },
};
</script>

<style scoped>
.bg-selected {
  background-color: #f8f8f8;
}

.text-20 {
  font-size: 20px;
}

.text-white {
  color: white;
}
</style>
