<template>
  <div
    class="login-wrapper flex flex-column justify-content-center align-items-center"
  >
    <b-card class="login-card">
      <form class="form-signin" @submit.prevent="login">
        <img class="logo mb-3" :src="company.logo" />
        <h1 class="h3 mb-4 font-weight-normal">{{ company.nome }}</h1>

        <b-form-input
          v-model="username"
          placeholder="Username"
          autofocus
          class="mb-3 input-field"
        ></b-form-input>
        <b-form-input
          v-model="password"
          type="password"
          placeholder="Password"
          class="mb-4 input-field"
        ></b-form-input>

        <button
          class="btn btn-lg btn-primary btn-block custom-button"
          type="submit"
          :disabled="loading"
        >
          {{ loading ? "Logging in..." : "Login" }}
        </button>

        <small v-if="loginError" class="text-danger">
          Invalid username or password
        </small>
      </form>

      <div class="mt-4 mb-3 text-center">
        <span class="forgot-password mr-1">Forgot your password?</span>
        <router-link
          :to="{ name: 'PasswordRecovery', params: { token: 'email-request' } }"
          class="reset-link"
          >Reset</router-link
        >
      </div>
      <div
        class="w-100 flex justify-content-center align-items-center mt-4 pt-4"
      >
        <small class="font-bold powered-by">
          Powered by
          <a
            href="https://martechrevolution.com/"
            target="_blank"
            class="powered-link ml-1"
            ><img
              class="logoMartech"
              src="https://martechrevolution.com/wp-content/uploads/2024/03/MartechBlu-800x221.png"
              alt=""
          /></a>
        </small>
      </div>
    </b-card>

    <img class="background-image" :src="backgroundImage" alt="" />
  </div>
</template>

<script>
import { AUTH_REQUEST } from "../store/actions/auth";
import store from "../store";
import { mapGetters } from "vuex";

export default {
  name: "Login",
  data() {
    return {
      username: "",
      password: "",
      loading: false,
      loginError: false,
    };
  },
  computed: {
    ...mapGetters(["company"]),
    backgroundImage() {
      return this.$store.state.config.config.url_background;
    },
  },
  methods: {
    login() {
      const authObject = {
        username: this.username,
        password: this.password,
      };
      this.loading = true;
      this.$store
        .dispatch(AUTH_REQUEST, authObject)
        .then(() => {
          this.$router.push("/");
        })
        .catch(() => {
          this.loginError = true;
          setTimeout(() => {
            this.loginError = false;
          }, 3000);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  beforeMount() {
    if (store.getters.isAuthenticated) {
      this.$router.push("/");
    }
  },
};
</script>

<style scoped>
/* General Styles */
.login-wrapper {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.login-card {
  width: 100%;
  max-width: 400px;
  background: #ffffff;
  padding: 40px 20px 10px 20px;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  text-align: center;
  z-index: 1000;
}

.logo {
  max-width: 150px;
  height: auto;
}

.input-field {
  border-radius: 25px;
  padding: 10px 15px;
  border: 1px solid #ddd;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

.custom-button {
  border-radius: 25px;
  background-color: #2575fc;
  border: none;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
}

.custom-button:hover {
  background-color: #1a5bbf;
}

.forgot-password {
  font-size: 0.9rem;
  color: #666;
}

.reset-link {
  font-size: 0.9rem;
  color: #2575fc;
  text-decoration: none;
}

.reset-link:hover {
  color: #1a5bbf;
  text-decoration: underline;
}

.powered-by {
  color: #7a7a7a;
  text-align: center;
}

.powered-link img {
  width: 80px;
  height: auto;
  margin-left: 5px;
}

@media screen and (max-width: 500px) {
  .login-card {
    width: 90%;
    padding: 20px;
  }

  .logo {
    max-width: 120px;
  }

  .login-wrapper {
    padding: 10px;
  }
}
</style>
