import {
  CONFIG_REQUEST,
  SET_CURRENT_COMPANY,
  SET_USER_LIST,
  SET_COMPANY_LIST,
} from "../actions/config";
import axios from "axios";
import { settingAddress } from "@/config/global";
import AxiosService from "../../axiosServices/AxiosService";

const configService = axios.create({
  baseURL: settingAddress,
});

const state = {
  config: {},
  loaded: false,
  currentCompany: 0,
  userList: [],
  companyList: [],
};

const getters = {
  languages: (state) => state.config.enabled_languages,
  default_lang: (state) =>
    state.config.enabled_languages.find(
      (x) => x.ID === state.config.default_language
    ),
  modules: (state) => state.config.enabled_modules,
  company: (state) => {
    return { nome: state.config.ragione_sociale, logo: state.config.url_logo };
  },
  base_address: (state) => state.config.endpoint_api,
  configuration_loaded: (state) => state.loaded,
  currentCompany: (state) => state.currentCompany,
  userList: (state) => state.userList,
  companyList: (state) => state.companyList,
};

const actions = {
  [CONFIG_REQUEST]: ({ commit }) => {
    configService.get().then((response) => {
      commit("SET_CONFIG", response.data);
    });
  },
  [SET_CURRENT_COMPANY]: ({ commit }, company) => {
    commit("SET_CURRENT_COMPANY", company);
  },
  [SET_USER_LIST]: async ({ commit }) => {
    const service = new AxiosService("User");
    try {
      const res = await service.read();
      commit("SET_USER_LIST", res);
    } catch (err) {
      console.log(err);
    }
  },
  [SET_COMPANY_LIST]: async ({ commit }, companyList) => {
    commit("SET_COMPANY_LIST", companyList);
  },
};

const mutations = {
  SET_CONFIG(state, config) {
    state.config = config;
    state.loaded = true;
  },
  SET_CURRENT_COMPANY(state, company) {
    state.currentCompany = company;
  },
  SET_USER_LIST(state, userList) {
    state.userList = userList;
  },
  SET_COMPANY_LIST(state, companyList) {
    state.companyList = companyList;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
