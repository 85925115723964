// store/modules/pusher.js
export const PUSHER_EVENT_RECEIVED = "PUSHER_EVENT_RECEIVED";

const state = {
  events: [],
};

const getters = {
  allPusherEvents: (state) => state.events,
};

const actions = {
  [PUSHER_EVENT_RECEIVED]: ({ commit }, event) => {
    commit(PUSHER_EVENT_RECEIVED, event);
  },
};

const mutations = {
  [PUSHER_EVENT_RECEIVED]: (state, event) => {
    state.events.push(event); // Add new events to the array
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
