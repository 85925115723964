import AxiosService from "../axiosServices/AxiosService";
import Talk from "talkjs";

export function isValidCodiceFiscale(codiceFiscale) {
  if (!codiceFiscale) return false;
  codiceFiscale = codiceFiscale.toUpperCase();
  const pattern = /^[A-Z]{6}[0-9LMNPQRSTUV]{2}[ABCDEHLMPRST]{1}[0-9LMNPQRSTUV]{2}[A-Z]{1}[0-9LMNPQRSTUV]{3}[A-Z]{1}$/;
  return pattern.test(codiceFiscale);
}

export function isValidPartitaIVA(partitaIVA) {
  if (!partitaIVA) return false;
  const pattern = /^[0-9]{11}$/;
  return pattern.test(partitaIVA);
}

async function createUser(user) {
  console.log("createUser: ", user);

  await Talk.ready;

  try {
    const talkUser = new Talk.User({
      id: user.id,
      name: user.fullname,
      email: user.email,
      photoUrl: user.avatar_url,
      welcomeMessage: `Conversazione con ${user.fullname}.`,
      role: user.role,
    });

    return talkUser;
  } catch (error) {
    console.error("Errore nella creazione dell'utente:", error);
    return null;
  }
}

async function createSession(talkAppId, me) {
  console.log("createSession: ", talkAppId, me);

  try {
    await Talk.ready;

    const talkUser = await createUser({ ...me, role: "BO" });

    return new Talk.Session({
      appId: talkAppId,
      me: talkUser,
    });
  } catch (error) {
    console.error("Errore nella creazione della sessione:", error);
    return null;
  }
}

async function createInbox(session, conversations) {
  try {
    await Talk.ready;
    const inbox = session.createInbox();

    for (const conversation of conversations) {
      inbox.select(conversation);
    }
    return inbox;
  } catch (error) {
    console.error("Errore nella creazione dell'inbox:", error);
    return null;
  }
}

async function createConversation(me, other, talkAppId, welcomeMessage) {
  console.log("CREATE CONVERSATION - input", {
    me,
    other,
    talkAppId,
    welcomeMessage,
  });

  try {
    await Talk.ready;
    const session = await createSession(talkAppId, me);

    console.log("CREATE CONVERSATION - session", session);

    if (!session) return null;

    const conversation = session.getOrCreateConversation(
      Talk.oneOnOneId(me, other)
    );

    console.log("CREATE CONVERSATION - conversation", conversation);

    conversation.setAttributes({
      welcomeMessages: [welcomeMessage || "AXERTA"],
    });

    const meUser = await createUser(me);
    const otherUser = await createUser(other);

    conversation.setParticipant(meUser);
    conversation.setParticipant(otherUser);

    return conversation;
  } catch (error) {
    console.error("Errore nella creazione della conversazione:", error);
    return null;
  }
}

async function creaNuovaChat(idDossier, idBo, idCie, chatid) {
  const endpoint = `/DossierManager/creaChatTkjs/${idDossier}/${idBo}/${idCie}/${chatid}`;
  const service = new AxiosService(endpoint);
  try {
    return await service.create();
  } catch (error) {
    console.error("Errore nella creazione della chat:", error);
    return null;
  }
}

async function getChatsDossier(idDossier, idBo) {
  const endpoint = `/DossierManager/GetChatsDossierTkjs/${idDossier}/${idBo}`;
  const service = new AxiosService(endpoint);
  try {
    return await service.read();
  } catch (error) {
    console.error("Errore nel recupero delle chat del dossier:", error);
    return null;
  }
}

async function getChatDossier(idDossier, idCie) {
  const endpoint = `/DossierManager/GetChatDossierTkjs/${idDossier}/${idCie}`;
  const service = new AxiosService(endpoint);
  try {
    return await service.read();
  } catch (error) {
    console.error("Errore nel recupero della chat del dossier:", error);
    return null;
  }
}

async function eliminaChat(id) {
  const endpoint = `/DossierManager/DeleteChatTkjs/${id}`;
  const service = new AxiosService(endpoint);
  try {
    return await service.read();
  } catch (error) {
    console.error("Errore nell'eliminazione della chat:", error);
    return null;
  }
}

async function shareMessage(idChat, message) {
  const endpoint = `/DossierManager/ShareMessageTkjs/${idChat}`;
  const service = new AxiosService(endpoint);
  const body = { messaggio: message };
  try {
    return await service.post(body);
  } catch (error) {
    console.error("Errore nella condivisione del messaggio:", error);
    return null;
  }
}

async function getSharedMessages(idDossier) {
  const endpoint = `/DossierManager/GetSharedMessagesTkjs/${idDossier}`;
  const service = new AxiosService(endpoint);
  try {
    return await service.read();
  } catch (error) {
    console.error("Errore nel recupero dei messaggi condivisi:", error);
    return null;
  }
}

async function deleteMessage(id) {
  const endpoint = `/DossierManager/DeleteMessageTkjs/${id}`;
  const service = new AxiosService(endpoint);
  try {
    return await service.read();
  } catch (error) {
    console.error("Errore nell'eliminazione del messaggio:", error);
    return null;
  }
}

async function createSuperChat({ boUser, cieUser, dossierId, companyNome }) {
  console.log("SUPER CHAT - input", {
    boUser,
    cieUser,
    dossierId,
    companyNome,
  });

  try {
    const bo = await createUser({
      id: `${companyNome}_BO_${boUser.id}`,
      fullname: boUser.fullname,
      email: boUser.email,
      avatarUrl:
        boUser.avatar_url ||
        `https://avatar.iran.liara.run/public/${boUser.id}`,
      role: "BO",
    });

    console.log("SUPER CHAT - bo", bo);

    const cie = await createUser({
      id: `${companyNome}_CIE_${cieUser.id}`,
      fullname: cieUser.fullname,
      email: cieUser.email,
      avatarUrl:
        cieUser.avatar_url ||
        `https://avatar.iran.liara.run/public/${cieUser.id}`,
      role: "CIE",
    });

    console.log("SUPER CHAT - cie", cie);

    const conversation = await createConversation(
      { ...bo, fullname: boUser.fullname },
      { ...cie, fullname: cieUser.fullname },
      "tjBGvoRX",
      `${companyNome} - Dossier: ${dossierId}`
    );

    if (!conversation) return null;

    conversation.setAttributes({
      welcomeMessages: [`${companyNome} - Dossier: ${dossierId}`],
    });

    conversation.sendMessage("Ciao, sono l'assistente virtuale. Cosa vuoi?");
    console.log("SUPER CHAT - conversation", conversation);

    // const chat = await creaNuovaChat(
    //   dossierId,
    //   boUser.id,
    //   cieUser.id,
    //   conversation.id
    // );

    return {
      talkConversation: conversation,
      // axertaChat: chat,
    };
  } catch (error) {
    console.error("Errore nella creazione della superchat:", error);
    return null;
  }
}

export const AxertaChat = {
  createInbox,
  createSession,
  createUser,
  createConversation,
  creaNuovaChat,
  getChatsDossier,
  getChatDossier,
  eliminaChat,
  shareMessage,
  getSharedMessages,
  deleteMessage,
  createSuperChat,
};
