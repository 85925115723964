<template>
  <footer
    class="layout_footer d-flex align-items-center justify-content-between"
  >
    <div>
      <b class="mr-1">WHAPPY</b
      ><span
        >Enterprise Gamification Platform - Copyright &copy;
        {{ currentYear }}</span
      >
    </div>
    <div>
      <router-link style="color: grey" :to="{ name: 'ChangeLog' }">
        ChangeLog
      </router-link>
    </div>
  </footer>
</template>

<script>
export default {
  methods: {
    onMenuToggle(event) {
      this.$emit("menu-toggle", event);
    },
  },
  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
  },
};
</script>
